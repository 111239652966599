import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import CustomLink from "../../components/link"
import pilares from "../../../static/img/blog/4pilares-570.jpg"
import micro from "../../../static/img/blog/micro-sitio.png"
import elementos from "../../../static/img/blog/elementos-ir-570.jpg"
import video from "../../../static/img/blog/video-difusion-570.jpg"
import noticias6 from "../../../static/img/blog/noticias-06.jpg"
import noticias7 from "../../../static/img/blog/noticias-07.jpg"
import noticias8 from "../../../static/img/blog/noticias-08.jpg"
import noticias9 from "../../../static/img/blog/noticias-09.jpg"
import noticias10 from "../../../static/img/blog/noticias-10.jpg"
import noticias11 from "../../../static/img/blog/noticias-11.jpg"
import noticias12 from "../../../static/img/blog/noticias-12.jpg"
import noticias13 from "../../../static/img/blog/noticias-13.png"
import noticias14 from "../../../static/img/blog/noticias-14.jpg"
import noticias15 from "../../../static/img/blog/noticias-15.jpg"
import noticias16 from "../../../static/img/blog/noticias-16.png"
import noticias18 from "../../../static/img/blog/noticias-18.jpg"
import noticias19 from "../../../static/img/blog/noticias-19.jpg"
import noticias20 from "../../../static/img/blog/noticias-20.jpg"
import noticias21 from "../../../static/img/blog/noticias-21.jpg"


const Blog = (props) => {

    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Blog"/>
            <Banner title="Blog"/>
            <section className="main_blog_area section_gap">
                <div className="container">
                    <div className="row main_blog_inner blog_col">
                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img src={pilares}
                                         alt={"Cuatro pilares para una exitosa comunicación con inversionistas"}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            19 Nov. 2019
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : Manuel Pérez
                </span>
                                            <span>Comunicación</span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/cuatro-pilares-exitosa-comunicacion-inversionistas">
                                        <h2>Cuatro pilares para una exitosa comunicación con inversionistas</h2>
                                    </CustomLink>

                                    <p>
                                        Para el desempeño de una exitosa función, el IRO “Investor Relations Officer”
                                        deberá estar, cada vez
                                        más, familiarizado con las entrañas de los estados financieros, y en particular
                                        con los usos y
                                        fuentes del estado de flujos de efectivo ...
                                    </p>
                                    <CustomLink className={"b_readmore btn_hover"}
                                                to="/blog/cuatro-pilares-exitosa-comunicacion-inversionistas">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={micro}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            19 Nov. 2019
                                        </a>
                                        <div className="blog_author_area">
                <span>
                    Por : J.A. Lovera
                </span>
                                            <span>Herramientas de RI</span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/estructura-sitio-inversionistas">
                                        <h2>Estructura del micro-sitio de RI </h2>
                                    </CustomLink>
                                    <p>
                                        La BMV ha tomado como modelos muchos lineamientos del NYSE y la SEC (*), entre
                                        ellos los
                                        concernientes a sitios de RI. De acuerdo con la SEC, conforme a lo establecido
                                        en la Regulación FD,
                                        2008, aplicable a emisoras con programas de ADRs …
                                    </p>
                                    <CustomLink className="b_readmore btn_hover"
                                                to="/blog/estructura-sitio-inversionistas">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={elementos}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            10 Dic. 2019
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : J.A. Lovera
                </span>
                                            <span>
                 Herramientas de RI
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/elementos-ir-micrositios">
                                        <h2>Elementos relevantes en sitios de RI</h2>
                                    </CustomLink>
                                    <p>
                                        El sitio de Relaciones con Inversionistas (RI) es de amplia importancia, tanto
                                        para inversionistas
                                        retail como para inversionistas institucionales. En el caso de inversionistas
                                        retail, el sitio de RI
                                        es su principal fuente de información …
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/elementos-ir-micrositios">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={video}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            13 Ene. 2020
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : J.A. Lovera
                </span>
                                            <span>
                  Herramientas de RI
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/video-difusion-ir">
                                        <h2>¿Video, la nueva ola de difusión para inversionistas?</h2>
                                    </CustomLink>

                                    <p>
                                        El uso de webcasts con tecnología de video streaming, en vez del uso de los
                                        clásicos servicios
                                        telefónicos de conference call, es visualizado en México como una práctica
                                        sofisticada de uso no
                                        extendido …
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/video-difusion-ir">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={noticias6}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            28 Oct. 2020
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : Miguel Bermejo
                </span>
                                            <span>
                  Análisis del mercado
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/elecciones-eeuu">
                                        <h2>Elecciones presidenciales de EE. UU. y su impacto en los mercados</h2>
                                    </CustomLink>

                                    <p>
                                        Las elecciones presidenciales de EE. UU., que se celebrarán el 3 de noviembre de
                                        2020,
                                        apuntan a ser unas de las más polémicas de los últimos años. En los años
                                        electorales “normales”, basados en …
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/elecciones-eeuu">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={noticias7}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            05 Nov. 2020
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : Manuel Pérez
                </span>
                                            <span>
                  Comunicación
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/evolucion-relacion">
                                        <h2>Evolución de la Relación con Inversionistas ante un mercado incremental de
                                            inversores retail</h2>
                                    </CustomLink>

                                    <p>
                                        En la actualidad, gran parte de los equipos de Relación con Inversionistas a
                                        nivel mundial (RI) se enfocan
                                        hacia los inversores institucionales. Sin embargo, los inversores retail han ido
                                        posicionándose …
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/evolucion-relacion">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={noticias8}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            18 Nov. 2020
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : J.A. Lovera
                </span>
                                            <span>
                  Comunicación
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/practicas-esg">
                                        <h2>Mejores Prácticas de Desarrollo de Reportes de Sostenibilidad y de Emisiones
                                            de Carbono en Canadá y Estados Unidos</h2>
                                    </CustomLink>

                                    <p>
                                        El término “Reporte de sostenibilidad”, se usa generalmente para abarcar una
                                        variedad de reportes corporativos, que incluyen informes
                                        de Responsabilidad Social Empresarial, de Inversión Socialmente …
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/practicas-esg">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={noticias9}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            19 Nov. 2020
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : J.A. Lovera
                </span>
                                            <span>
                  Herramientas de RI
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/buen-sitio-ri">
                                        <h2>¿Qué se debe considerar en un buen sitio web de RI?</h2>
                                    </CustomLink>

                                    <p>
                                        Los mejores sitios web de Relación con Inversionistas son los que proporcionan a
                                        los inversores un contexto
                                        convincente e informativo que les permita comprender con claridad el negocio y
                                        la situación …
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/buen-sitio-ri">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={noticias10}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            20 Nov. 2020
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : Ricardo Ávila
                </span>
                                            <span>
                  Comunicación
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/diversidad-juntas">
                                        <h2>Diversidad en las juntas directivas</h2>
                                    </CustomLink>

                                    <p>
                                        A lo largo de los últimos años, la diversidad en las juntas directivas de las
                                        compañías (en materia de género, experiencia, áreas de expertise, etc.) se ha
                                        convertido en un área de enfoque para los inversionistas …
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/diversidad-juntas">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={noticias11}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            04 Dic. 2020
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : Miguel Bermejo
                </span>
                                            <span>
                  Comunicación
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/restauracion-confianza">
                                        <h2>Restauración de la confianza luego de una mala racha</h2>
                                    </CustomLink>

                                    <p>
                                        La crisis sanitaria por la pandemia de COVID-19 ha traído consigo desafíos
                                        importantes para la
                                        economía mundial, afectando a empresas, gobiernos, inversionistas y la población
                                        en general …
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/restauracion-confianza">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={noticias12}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            08 Dic. 2020
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : Rodrigo Hernández
                </span>
                                            <span>
                  Análisis del mercado
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/optimismo">
                                        <h2>Optimismo en el mercado de valores</h2>
                                    </CustomLink>

                                    <p>
                                        De acuerdo con la última encuesta global de administradores de fondos de Bank of
                                        America (BofA), realizada
                                        entre el 6 y 12 de noviembre, la confianza en el mercado de valores alcanzó su
                                        punto más alto en …
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/optimismo">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={noticias13}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            11 Dic. 2020
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : Ricardo Ávila
                </span>
                                            <span>
                  Comunicación
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/tecnologiari">
                                        <h2>La inversión en tecnología de RI disminuye a pesar de su uso
                                            incremental</h2>
                                    </CustomLink>

                                    <p>
                                        De acuerdo con la encuesta global de la revista IR Magazine, realizada en el
                                        3T20, al compararse con
                                        los últimos dos años los equipos de RI han manifestado un uso incremental de
                                        herramientas tecnológicas …
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/tecnologiari">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={noticias14}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            15 Dic. 2020
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : Miguel Bermejo
                </span>
                                            <span>
                  Análisis del mercado
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/catalizadores">
                                        <h2>Catalizadores positivos en un entorno incierto</h2>
                                    </CustomLink>

                                    <p>
                                        A pesar de la recesión económica que trajo consigo la pandemia de COVID-19, de
                                        acuerdo con Bank
                                        of America (BofA), durante la semana que finalizó el 18 de noviembre, la
                                        asignación de recursos por
                                        parte de …
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/catalizadores">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={noticias15}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            11 Ene. 2021
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : Rodrigo Hernández
                </span>
                                            <span>
                  Comunicación
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/inversores">
                                        <h2>¿Cómo atraer inversores potenciales?</h2>
                                    </CustomLink>

                                    <p>
                                        La selección de inversores (<em>investor targeting</em>) es el proceso que se
                                        lleva a cabo para identificar
                                        posibles inversionistas que contribuyan a la generación incremental de valor
                                        para una compañía …
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/inversores">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={noticias16}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            12 Ene. 2021
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : Ricardo Ávila
                </span>
                                            <span>
                  Análisis del mercado
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/vistazo">
                                        <h2>2021: Un vistazo a la economía mundial</h2>
                                    </CustomLink>

                                    <p>
                                        El 2020 trajo consigo una serie de acontecimientos para los cuales nadie se
                                        encontraba preparado.
                                        El brote del virus SARS-CoV-2 (a finales de 2019) en la ciudad de Wuhan, China,
                                        junto con su posterior …
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/vistazo">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={noticias18}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            10 Mar. 2021
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : Miguel Bermejo
                </span>
                                            <span>
                  Análisis del mercado
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/presupuesto-ri">
                                        <h2>Aunque los presupuestos de RI disminuyen durante la pandemia, los equipos se
                                            mantienen sólidos</h2>
                                    </CustomLink>

                                    <p>
                                        Como consecuencia de la pandemia de COVID-19, las compañías se han enfrentado a
                                        un entorno muy complejo,
                                        en el que se han presentado recortes tanto en la plantilla laboral como en los
                                        presupuestos...
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/presupuesto-ri">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={noticias19}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            20 abril. 2021
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : Abigail Ciau
                </span>
                                            <span>
                  Análisis del mercado
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/perspectivas-2021">
                                        <h2>Un vistazo a las perspectivas de 2021</h2>
                                    </CustomLink>

                                    <p>
                                        El 2020 fue uno de los años más complicados de la historia, con el surgimiento
                                        de la pandemia por el COVID-19
                                        y su profundo impacto en la economía global, que conllevó a la peor...
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/perspectivas-2021">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={noticias20}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            20 septiembre. 2021
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : Ricardo Ávila
                </span>
                                            <span>
                  Comunicación
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/dos-practicas">
                                        <h2>Dos prácticas de relación con inversionistas que tomaron mayor relevancia
                                            debido al COVID-19 </h2>
                                    </CustomLink>

                                    <p>
                                        Como respuesta a las limitantes que resultaron de la pandemia de COVID-19, las
                                        compañías se vieron en la
                                        necesidad de implementar diversos cambios en el área de relación con
                                        inversionistas...
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/dos-practicas">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-6 wow fadeInUp">
                            <div className="main_blog_item blog_col_item">
                                <div className="main_blog_image img_hover">
                                    <img alt={" "} src={noticias21}/>
                                </div>
                                <div className="main_blog_text">
                                    <div className="post_info">
                                        <a href="#" className="date">
                                            5 agosto. 2021
                                        </a>
                                        <div className="blog_author_area">
                <span>
                  Por : Miguel Bermejo
                </span>
                                            <span>
                  Análisis del mercado
                </span>

                                        </div>
                                    </div>
                                    <CustomLink to="/blog/fondos-cobertura">
                                        <h2>Fondos de cobertura </h2>
                                    </CustomLink>

                                    <p>
                                        Actualmente, en algunas partes del mundo existe un exceso de ahorro a causa,
                                        entre otras cosas, de los estímulos fiscales otorgados por diferentes gobiernos
                                        y los confinamientos impuestos para afrontar la retadora coyuntura propiciada
                                        por la pandemia de COVID-19...
                                    </p>
                                    <CustomLink className="b_readmore btn_hover" to="/blog/fondos-cobertura">
                                        Leer artículo
                                    </CustomLink>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Blog
